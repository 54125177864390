



































import { Research, Target } from '@app/models';
import { Vue, Component, Prop } from 'vue-property-decorator';

import ArrowLeft from '../../components/6-other/arrow-left.vue';
import ResearchTargetTabs from '../../components/research/research-target-tabs.vue';
import { br2nl, nl2br } from '../../utils/simple-functions';

@Component({
  components: { ArrowLeft, ResearchTargetTabs },
})
export default class ResearchInvitation extends Vue {
  @Prop()
  public value!: Research.Mutable;

  @Prop()
  public targets!: Target[];

  private target = this.targets[0] || '';

  public get subject() {
    const { subject } = this.value.invitation;
    return typeof subject === 'string' ? subject : subject[this.target] || '';
  }

  public set subject(value: string) {
    if (typeof this.value.invitation.subject === 'string')
      this.value.invitation.subject = value;
    else this.value.invitation.subject[this.target] = value;
  }

  public get message() {
    const { message } = this.value.invitation;
    const text = typeof message === 'string' ? message : message[this.target];
    return text ? br2nl(text) : '';
  }

  public set message(value: string) {
    const text = nl2br(value);
    if (typeof this.value.invitation.message === 'string')
      this.value.invitation.message = text;
    else this.value.invitation.message[this.target] = text;
  }

  private get endDate() {
    return this.value.date.end
      ? this.value.date.end.toDate().toLocaleDateString()
      : '';
  }

  public resetMessage() {
    const baseKey = 'invitation-text-content';
    const targetKey = `${baseKey}-${this.target}`;

    this.subject = `${this.$company.name} - ${this.value.title}`;
    this.message = this.$i18n.exists(targetKey)
      ? this.$t(targetKey)
      : this.$t(baseKey);
  }
}
